import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

import Layout from "../../../../../Layout"
import Seo from "../../../../../components/Seo/Seo"
import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb'
import TitleSection from '../../../../../Molecules/TitleSection/TitleSection'
import TitlePage from '../../../../../Molecules/TitlePage/TitlePage'
import VideoYoutube from '../../../../../components/Video/VideoYoutube'
import { Button } from '../../../../../Molecules/Button/Button'
import PageBannerStyle from "../../../../../components/PageBannerStyle/PageBannerStyle";

import VisuelComponent from '../../../../../components/ContentType/VisuelComponent/VisuelComponent'

import BImage from '../../../../../assets/images/varlorcol_desktop.png'

import './styles.scss';
import { RoundedArrow } from "../../../../../Atoms/Icons/Icons";

var classNames = require('classnames');

const ColasHistorySingle = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "single-article-visu-desktop.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return(
      <Layout>
        <Seo 
          title="Colas History" 
          description="Description de la page Colas History"
        />
        <div className={classNames('page_template', 'colas_history_inner_page')}>
          <PageBannerStyle 
            visuel={ data.file.childImageSharp.fluid} scroll={true}
            breadcrumbData={{ 
              grandParentPage: {title: "Acceuil", url: "/"},
              parentPage: {title: "Finance", url: "/"},
              currentPage: {title: "Réunion des analystes", url: "/finance/analyst-meeting"}
            }} 
          >
            <div className="wrapper_page">
              <TitlePage 
                color="color_white"
                title="Années 2010"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in efficitur ante. Duis consequat ex at urna iaculis, id malesuada erat pretium. Morbi facilisis, nunc vitae luctus dignissim, neque augue dignissim nisl, a dictum est nunc at quam. Duis placerat mi a nisi faucibus vulputate. Nulla quis auctor sapien."
              />
            </div>
          </PageBannerStyle>
          <section className={classNames('section_content', 'section_lauch_wattway')}>
            <div className="wrapper_page_xs">
              <TitleSection
                title="Lancement de la route photovoltaïque Wattway"
              />
              <p>Conçu par le Campus Scientifique et Technique en collaboration avec l’INES (Institut National de l’Énergie Solaire) et présenté en 2015, Wattway est le premier revêtement routier photovoltaïque au monde. La chaussée devient capable de fournir de l’électricité propre et renouvelable tout en permettant la circulation de tout type de véhicule.</p>
              <VisuelComponent
                extremeLeft={true}
                visuel = {data.file.childImageSharp}
                size = "two_third"
                alt = ""
              />
            </div>
          </section>
          <section className="section_content">
            <div className="wrapper_page_xs">
              <TitleSection
                title="Lancement de la route photovoltaïque Wattway"
              />
              <p>Colas crée en 2017 Colas Projects, pour répondre à la demande croissante de ses clients publics ou privés dans le domaine des projets complexes, de type partenariats public-privé (PPP), concessions ou contrats de maintenance pluriannuels, et des projets de taille importante comme les grands chantiers routiers et aéroportuaires et les grands projets ferroviaires à l’international. Cette entité permet de concentrer les moyens et les expertises nécessaires pour étudier et réaliser ce type de projets dans les meilleures conditions, aux côtés des filiales routières et ferroviaires concernées.</p>
                <div className="video_container">
                  <VideoYoutube
                    cover = {BImage}
                    obj = {{
                      height: '390',
                      width: '640',
                      playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0,
                        controls: 0,
                        disablekb: 1,
                        rel: 0,
                        showinfo: 0,
                      },
                    }}
                  />
                </div>
            </div>
          </section>
          <section className="section_content">
            <div className="wrapper_page_xs">
              <TitleSection
                title="Lancement de la route photovoltaïque Wattway"
              />
              <p>Colas crée en 2017 Colas Projects, pour répondre à la demande croissante de ses clients publics ou privés dans le domaine des projets complexes, de type partenariats public-privé (PPP), concessions ou contrats de maintenance pluriannuels, et des projets de taille importante comme les grands chantiers routiers et aéroportuaires et les grands projets ferroviaires à l’international. Cette entité permet de concentrer les moyens et les expertises nécessaires pour étudier et réaliser ce type de projets dans les meilleures conditions, aux côtés des filiales routières et ferroviaires concernées.</p>
                <div className="video_container">
                  <VideoYoutube
                    cover = {BImage}
                    obj = {{
                      height: '390',
                      width: '640',
                      playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0,
                        controls: 0,
                        disablekb: 1,
                        rel: 0,
                        showinfo: 0,
                      },
                    }}
                  />
                </div>
            </div>
          </section>
         
        </div>
      </Layout>
  )
}

export default ColasHistorySingle